import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

//引入轮播图
import "../node_modules/swiper/swiper.min.css"

import axios from 'axios'
Vue.prototype.$http = axios;
axios.defaults.baseURL = 'https://web.maijieduo.com/api/m4141/'; //正式域名
// axios.defaults.baseURL = 'https://349730k70o.oicp.vip/api/m4141/'; //正式域名
// axios.defaults.baseURL = ' https://yufabu.saizhuge.net/api/m4141/' //预发布域名
axios.defaults.headers.post['Content-Type'] = 'application/json'
// Vue.config.errorHandler = (err, vm, info) => {
//     console.error('Vue error:', err, info);
// };

//复制功能
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

// vue预览图片
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
   Options: {
      'inline': true,
      'button': true,
      'navbar': true,
      'title': true,
      'toolbar': true,
      'tooltip': true,
      'movable': true,
      'zoomable': true,
      'rotatable': true,
      'scalable': true,
      'transition': true,
      'fullscreen': true,
      'keyboard': true,
      'url': 'data-source'
} })

//使用高德地图展示
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '73d80c60a8a72d4fccb5beafaed20013',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});

import SIdentify from './components/identify'
Vue.component('s-identify', SIdentify)

// let service = "";
// service = JSON.parse(localStorage.getItem('userInfo'))
// 请求拦截器
axios.interceptors.request.use((config) => {
	if(localStorage.getItem("userInfo")){
		var token = JSON.parse(localStorage.getItem("userInfo")).user_token;
	}else{
		var token = "";
	}
	if(token) {
		config.headers['user-token'] = token;
		// config.headers['user-token'] = "b6aa2b56-79d3-98b8-6774-49204045e22b";
	}else{
		config.headers['user-token'] = "";
	}
    config.url = config.url + "?XDEBUG_SESSION_START=PHPSTORM"
    // cookie.set("XDEBUG_SESSION","PHPSTORM")
    return config
}, error => Promise.reject(error))

axios.interceptors.response.use((response) => {
	if (response.data.code == '-201') {
		localStorage.clear();
		router.push({name:"logIn"})
	}
	return response
})


// ElementUI组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

//视频依赖
// import { videoPlayer } from "vue-video-player";
// import VueVideoPlayer from 'vue-video-player'
// require videojs style
// import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
// Vue.use(VueVideoPlayer)

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
