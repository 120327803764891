<template>
	<div class="home">
		<HelloWorld></HelloWorld>
		<div class="home_one">
			<!-- 轮播图 -->
			<div class="index_swiper">
				<el-carousel trigger="click" height="422px">
					<el-carousel-item v-for="(item,index) in bannerList" :key="index">
						<img :src="item.thumb" alt="" @click="swiperclick(item.href)">
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="home_one_right">
				<div class="home_one_right_header">
					<img :src="userInfo.avatar" alt="" v-if="userInfo" @click="goselfcenter(1)">
					<img src="../assets/25.png" alt="" v-else>
					<div class="home_one_right_header_right">
						<p>您好~ </p>
						<p>欢迎来到麦秸垛商城！~ </p>
					</div>
				</div>
				<div class="home_one_right_login" v-if="!userInfo">
					<div class="home_one_right_login_l" @click="loginregir(2)">
						<span>注册</span>
					</div>
					<div class="home_one_right_login_r" @click="loginregir(1)">
						<span>登录</span>
					</div>
				</div>
				<div class="home_one_right_content">
					<div class="home_one_right_list" @click="goselfcenter(2)">
						<img src="../assets/26.png" alt="">
						<span>我的收藏</span>
					</div>
					<div class="home_one_right_list" @click="goselfcenter(3)">
						<img src="../assets/27.png" alt="">
						<span>我的足迹</span>
					</div>
					<div class="home_one_right_list" @click="goselfcenter(4)">
						<img src="../assets/28.png" alt="">
						<span>意见反馈</span>
					</div>
					<div class="home_one_right_list" @click="goselfcenter(5)">
						<img src="../assets/29.png" alt="">
						<span>我的消息</span>
					</div>
					<div class="home_one_right_list" @click="goselfcenter(6)">
						<img src="../assets/30.png" alt="">
						<span>我的客服</span>
					</div>
					<div class="home_one_right_list" @click="goselfcenter(7)">
						<img src="../assets/31.png" alt="">
						<span>我的订单</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 新品专区 -->
		<div class="home_two">
			<div class="home_two_header">
				<div class="home_two_header_content">
					<div class="home_two_header_border_1"></div>
					<div class="home_two_header_border_2"></div>
					<div class="home_two_header_text">
						<span>新品专区</span>
					</div>
					<div class="home_two_header_border_2"></div>
					<div class="home_two_header_border_1"></div>
				</div>
				<div class="home_two_header_more" @click="morenewsshop">
					<span>查看更多></span>
				</div>
			</div>
			<div class="home_two_content">
				<div class="home_two_list" v-for="item in newsgoods_list" @click="goodsdetail(item.aid)">
					<img class="home_two_list_img" :src="item.thumb" alt="">
					<img class="home_two_list_bq" src="../assets/33.png" alt="" v-if="item.is_new==1">
					<div class="home_two_list_bq_text" v-if="item.is_new==1">
						<span>新品</span>
					</div>
					<div class="home_two_list_name">
						<span>{{item.name}}</span>
					</div>
					<div class="home_two_list_price">
						<div class="home_two_list_price_one">
							<span>¥</span>{{item.shop_price}}
						</div>
						<div class="home_two_list_price_two">
							<span>¥</span>{{item.market_price}}
						</div>
					</div>
					<div class="home_two_list_bottom">
						<div class="home_two_list_bottom_l">
							<span>当日发货</span>
						</div>
						<div class="home_two_list_bottom_r">
							<span>销量{{item.sales_sum}}</span>
						</div>
					</div>
				</div>
				<noshuju v-if="newsgoods_list.length==0"></noshuju>
			</div>
		</div>

		<!-- 猜你喜欢 -->
		<div class="home_two">
			<div class="home_two_header">
				<div class="home_two_header_content">
					<div class="home_two_header_border_1"></div>
					<div class="home_two_header_border_2"></div>
					<div class="home_two_header_text">
						<span>猜你喜欢</span>
					</div>
					<div class="home_two_header_border_2"></div>
					<div class="home_two_header_border_1"></div>
				</div>
				<div class="home_two_header_more">
					<span></span>
				</div>
			</div>
			<div class="home_two_content">
				<div class="home_two_list" v-for="item in likegoods_list" @click="goodsdetail(item.aid)">
					<img class="home_two_list_img" :src="item.thumb" alt="">
					<img class="home_two_list_bq" src="../assets/33.png" alt="" v-if="item.is_new==1">
					<div class="home_two_list_bq_text" v-if="item.is_new==1">
						<span>新品</span>
					</div>
					<div class="home_two_list_name">
						<span>{{item.name}}</span>
					</div>
					<div class="home_two_list_price">
						<div class="home_two_list_price_one">
							<span>¥</span>{{item.shop_price}}
						</div>
						<div class="home_two_list_price_two">
							<span>¥</span>{{item.market_price}}
						</div>
					</div>
					<div class="home_two_list_bottom">
						<div class="home_two_list_bottom_l">
							<span>当日发货</span>
						</div>
						<div class="home_two_list_bottom_r">
							<span>销量{{item.sales_sum}}</span>
						</div>
					</div>
				</div>
				<noshuju v-if="newsgoods_list.length==0"></noshuju>
			</div>
		</div>
		<footerBottom></footerBottom>
	</div>
</template>

<script>
	import HelloWorld from '@/components/HelloWorld.vue';
	import footerBottom from '@/components/footerBottom.vue';
	import noshuju from '@/components/noshuju.vue';
	export default {
		name: 'HomeView',
		components: {
			HelloWorld,
			footerBottom,
			noshuju,
		},
		data() {
			return {
				userInfo:"",//个人中心
				bannerList: [], //轮播图
				newsgoods_list:[],//新品专区
				likegoods_list:[],//猜你喜欢
			}
		},
		create() {

		},
		mounted() {
			if(localStorage.getItem("userInfo")){
				this.get_user_info();
				// this.userInfo =  JSON.parse(localStorage.getItem("userInfo"));
			}else{
				this.userInfo = "";
			}
			this.get_sender();//根据定位获取仓库信息
			this.get_banner();//首页轮播
		},
		methods: {
			//获取个人中心
			get_user_info() {
				this.$http({
					url: '5c78c4772da97',
					method: 'get',
				}).then((res) => {
					if (res.data.code == 1) {
						this.userInfo = res.data.data;
					}
				});
			},

			//获取仓库
			get_sender() {
				this.$http({
					url: '659515265f02f',
					method: 'post',
					data: JSON.stringify({
						lat: localStorage.getItem("latitude"),
						lng: localStorage.getItem("longitude"),
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.get_goods_list();//获取新品商品
						this.get_goods_listlike();//猜你喜欢
					} else if (res.data.code == 0) {
						this.$confirm(res.data.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				});
			},

			//获取首页轮播数据
			get_banner() {
				this.$http({
					url: '641e624160dd0',
					method: 'post',
					data:JSON.stringify({
						type:1,
						lat:localStorage.getItem("latitude"),
						lng:localStorage.getItem("longitude"),
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.bannerList = res.data.data;
					}
				});
			},

			//轮播图跳转
			swiperclick(href){
				let aid = href.split("?id=")[1].split("&")[0];
				this.$router.push({path:"goodsdetail",query:{aid:aid}})
			},

			//获取商品列表（新品）
			get_goods_list(){
				this.$http({
					url: '64184ac1cafc3',
					method: 'post',
					data:JSON.stringify({
						page:1,
						list_rows:"5",
						goods_type:4,
						lat:localStorage.getItem("latitude"),
						lng:localStorage.getItem("longitude"),
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.newsgoods_list = res.data.data.data;
					}
				});
			},

			//查看更多新品专区
			morenewsshop(){
				localStorage.setItem("headerindex",2);
				this.$router.push({path:"newgoods"});
			},


			//猜你喜欢
			get_goods_listlike(){
				this.$http({
					url: '64184ac1cafc3',
					method: 'post',
					data:JSON.stringify({
						page:1,
						goods_type:2,
						lat:localStorage.getItem("latitude"),
						lng:localStorage.getItem("longitude"),
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.likegoods_list = res.data.data.data;
					}
				});
			},

			//跳转详情
			goodsdetail(aid){
        if(!this.userInfo){
          this.$message({
            message:"请先登录",
            type: "error"
          })
          return
        }
				this.$router.push({path:"goodsdetail",query:{aid:aid}})
			},

			//跳转登录注册的事件
			loginregir(type){
				this.$router.push({path:"login",query:{type:type}})
			},

			//跳转个人中心模块
			goselfcenter(type){
				if(!this.userInfo){
					this.$message({
						message:"请先登录",
						type: "error"
					})
				}else{
					if(type==1){
						this.$router.push({path:"selfcenter"})
					}else if(type==2){
						localStorage.setItem("leftindex",5);
						this.$router.push({path:"usercollect"})
					}else if(type==3){
						localStorage.setItem("leftindex",6);
						this.$router.push({path:"userfooter"})
					}else if(type==4){
						localStorage.setItem("leftindex",16);
						this.$router.push({path:"faceback"})
					}else if(type==5){
						localStorage.setItem("leftindex",7);
						this.$router.push({path:"usermessage"})
					}else if(type==6){
						localStorage.setItem("leftindex",7);
						this.$router.push({path:"usermessage"})
					}else if(type==7){
						localStorage.setItem("leftindex",1);
						this.$router.push({path:"orderlist"})
					}
				}
			}
		}
	}
</script>
<style scoped>
	.home {
		width: 100%;
		background-color:#F7F7F7;
		overflow: hidden;
	}
	.home_one{
		width: 1200px;
		overflow: hidden;
		margin: 0 auto;
	}
	.index_swiper {
		width: 980px;
		height: 422px;
		float: left;
	}

	.index_swiper img {
		width: 100%;
		height: 422px;
	}
	.home_one_right{
		width: 200px;
		height: auto;
		float: right;
		background-color: #Fff;
	}
	.home_one_right_header{
		width: 100%;
		overflow: hidden;
		margin-top: 35px;
	}
	.home_one_right_header img{
		width: 40px;
		height: 40px;
		display: block;
		border-radius: 50%;
		margin-left: 16px;
		float: left;
	}
	.home_one_right_header_right{
		width: auto;
		font-size: 12px;
		font-weight: 400;
		color: #333333;
		float: left;
		margin-left: 8px;
		margin-top: 4px;
	}
	.home_one_right_login{
		width: 100%;
		overflow: hidden;
		text-align: center;
		line-height: 28px;
	}
	.home_one_right_login_l{
		width: 66px;
		height: 28px;
		background: #10C380;
		border-radius: 4px 4px 4px 4px;
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
		float: left;
		cursor: pointer;
		margin-left: ;
		margin-top: 16px;
		margin-left: 27px;
	}
	.home_one_right_login_r{
		width: 64px;
		height: 28px;
		background: #F6F6F6;
		border-radius: 4px 4px 4px 4px;
		font-size: 12px;
		font-weight: 400;
		color: #666666;
		float: right;
		cursor: pointer;
		margin-top: 16px;
		margin-right: 27px;
	}
	.home_one_right_content{
		width: 100%;
		overflow: hidden;
		margin-top: 30px;
	}
	.home_one_right_list{
		width: 100px;
		overflow: hidden;
		float: left;
		text-align: center;
		margin-bottom: 30px;
		cursor: pointer;
	}
	.home_one_right_list img{
		width: 32px;
		height: 32px;
		display: block;
		margin: 10px auto;
	}
	.home_two{
		width: 1200px;
		overflow: hidden;
		margin: 40px auto;
	}
	.home_two_header{
		width: 100%;
		height: auto;
		position: relative;
	}
	.home_two_header_content{
		width: 250px;
		height: auto;
		margin: 0 auto;
	}
	.home_two_header_border_1{
		width: 10px;
		height: 10px;
		background: #10C380;
		border-radius: 2px 2px 2px 2px;
		float: left;
		opacity: 0.7;
		transform: rotate(45deg);
		margin-right: 20px;
		margin-top: 13px;
	}
	.home_two_header_border_2{
		width: 13px;
		height: 13px;
		background: #10C380;
		border-radius: 2px 2px 2px 2px;
		float: left;
		transform: rotate(45deg);
		margin-right: 20px;
		margin-top: 10px;
	}
	.home_two_header_text{
		width: auto;
		height: auto;
		font-size: 24px;
		font-weight: bold;
		color: #333333;
		float: left;
		margin-right: 20px;
	}
	.home_two_header_more{
		width: 200px;
		height: 50px;
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		float: right;
		text-align: right;
		cursor: pointer;
	}
	.home_two_content{
		width: 1200px;
		overflow: hidden;
		background: #FFFFFF;
		margin: 20px auto;
	}
	.home_two_list{
		width: 210px;
		height: 384px;
		position: relative;
		float: left;
		margin-left: 24px;
		margin-top: 24px;
		cursor: pointer;
	}
	.home_two_list_img{
		width: 100%;
		height: 210px;
	}
	.home_two_list_name {
		font-size: 16px;
		font-weight: 400;
		color: #333333;
		width: 200px;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2; /* 这里是超出几行省略 */
		overflow: hidden;
	}
	.home_two_list_bq{
		width: 44px;
		height: 26px;
		position: absolute;
		left: 0;
		top: 0;
	}
	.home_two_list_bq_text{
		width: 44px;
		height: 26px;
		position: absolute;
		left: 0;
		top: 0;
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 26px;
	}
	.home_two_list_price{
		width: 100%;
		height: auto;
		margin-top: 12px;
	}
	.home_two_list_price_one{
		color: #F02325;
		font-size: 20px;
		font-weight: bold;
		float: left;
	}
	.home_two_list_price_one span{
		font-size: 12px;
	}
	.home_two_list_price_two{
		float: left;
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		text-decoration: line-through;
		margin-left: 16px;
		margin-top: 4px;
	}
	.home_two_list_bottom{
		width: 100%;
		height: auto;
		clear: both;
	}
	.home_two_list_bottom_l{
		width: 68px;
		height: 24px;
		border-radius: 2px 2px 2px 2px;
		border: 1px solid #10C380;
		color: #10C380;
		text-align: center;
		line-height: 24px;
		margin-top: 14px;
		float: left;
	}
	.home_two_list_bottom_r{
		width: auto;
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		float: right;
		margin-top: 14px;
	}
</style>
